import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid';
import React, {forwardRef, useState} from 'react';
import ReactSlider from 'react-slider';
const Slider = forwardRef<
  ReactSlider<number>,
  {
    max: number;
    min: number;
    defaultValue?: number;
    onChange?: (v: number) => void;
    value?: number;
  }
>(({max, min, defaultValue, value, onChange}, ref) => {
  return (
    <ReactSlider
      ref={ref}
      value={value}
      onChange={(v) => onChange(v)}
      className="outline-none h-10"
      max={max}
      min={min}
      renderThumb={(props) => (
        <span
          {...props}
          className={
            'w-10 h-10 bg-indigo-600 rounded-full flex items-center justify-center ' +
            (props.className || '')
          }
        >
          <ChevronLeftIcon className="w-5 h-5 -mr-1 text-white" />
          <ChevronRightIcon className="w-5 h-5 -ml-1 text-white" />
        </span>
      )}
      renderTrack={(props, state) => (
        <div
          {...props}
          className={
            (state.index === 0 ? 'bg-indigo-300' : 'bg-gray-100') +
              ' rounded-full h-2 top-4 ' +
              props.className || ''
          }
        />
      )}
    />
  );
});

export default Slider;
