import {
  ChevronDownIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import React, { useState } from 'react';

const Accordion = ({
  heading,
  sub,
  initiallyOpen = false,
}: {
  heading: string;
  sub: string;
  initiallyOpen?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(initiallyOpen);
  return (
    <div className="mb-10 accordion">
      <div className="flex cursor-pointer" onClick={() => setIsOpen((f) => !f)}>
        <QuestionMarkCircleIcon className="flex-shrink-0 w-6 h-6 text-indigo-600 mr-3 mt-1" />
        <div className="sub-sm font-semibold pt-1 md:pt-0.5 pr-3">
          {heading}
        </div>
        <ChevronDownIcon
          className={
            'flex-shrink-0 w-6 h-6 ml-auto mt-1 transition-transform transform' +
            (isOpen ? ' rotate-180' : '')
          }
        />
      </div>
      <div
        className={
          'sub-sm px-9 overflow-hidden transition-all h-auto ' +
          (isOpen ? 'max-h-96 opacity-100 mt-4' : 'max-h-0 opacity-0 mb-0 mt-0')
        }
        dangerouslySetInnerHTML={{ __html: sub }}
      />
    </div>
  );
};

export default Accordion;
