import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useRef, useState } from 'react';
import ReactSlider from 'react-slider';
import { testimonials } from '.';
import Accordion from '../components/atoms/Accordion';
import Quotes from '../components/atoms/Quotes';
import Slider from '../components/atoms/Slider';
import Testimonial from '../components/atoms/Testimonial';
import CTA, { CTAButton } from '../components/CTA';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const features = [
  'Tilbud, ordrer og fakturering',
  'CRM-system',
  'Produkter med flere leverandører',
  'Nem lagerstyring',
  'Automatisk valutakonvertering',
  'Overskueligt og brugervenligt design',
  'Indkøb og leverandører',
  'Integration med dit økonomisystem',
  'Automatisk prissætning',
  'Flere valuta',
];

const faqs = [
  {
    question: 'Hvordan virker den gratis prøve af Sails?',
    answer:
      'Når du opretter en konto på Sails starter din gratis prøveperiode helt automatisk. Under din prøveperiode har du fuld adgang til alle funktioner i Sails og du kan invitere alle dine kollegaer.',
  },
  {
    question: 'Skal jeg skrive mine kortoplysninger for at oprette en konto?',
    answer:
      'Vi har gjort det let at komme igang og du skal derfor ikke bruge dine kortoplysninger til at oprette en Sails konto.',
  },
  {
    question: 'Hvad sker der efter min gratis prøve?',
    answer:
      'Et stykke tid før din gratis prøve udløber sender vi dig en mail med en påmindelse om at du skal oprette et betalt abonnement for at fortsætte med at bruge Sails. Hvis du ikke opgraderer til et betalt abonnement, sætter vi din konto på hold, og sletter den efter 90 dage. Du kan altid kontakte os hvis du ikke fik afprøvet Sails i din gratis periode - så giver vi dig lidt mere tid at teste Sails af i.',
  },
  {
    question: 'Hvordan fungerer jeres betalte abonnement?',
    answer:
      'Med et betalt Sails abonnement betaler du kun for aktive brugere. En aktiv bruger, er en bruger som har adgang til din Sails konto. Du betaler kun for den tid dine brugere er aktive i Sails. Det betyder, at hvis du i første del af en måned har 12 aktive brugere, og i sidste del har 13 aktive brugere, betaler du kun for 12 brugere den første halvdel af måneden og 13 brugere den sidste halvdel.',
  },
  {
    question: 'Hvordan opgraderer jeg min konto til et betalt abonnement?',
    answer:
      'For at bruge Sails skal du have et betalt abonnement. For at oprette et abonnement, skal du logge ind på din Sails konto og trykke på den blå knap i øverste venstre hjørne. Ellers er du meget velkommen til at kontakte os, så hjælper vi dig med at oprette et abonnement.',
  },
  {
    question: 'Kan jeg opgradere og nedgradere Sails når som helst?',
    answer:
      'Selvfølgelig. Sails’ fleksible abonnement gør det muligt for dig at opgradere, nedgradere eller opsige dit abonnement helt efter dit behov. Du har ikke nogen binding i dit abonnement, og opsigelser sker i slutningen af din betalingsperiode.',
  },
  {
    question: 'Er der et opsætnings- eller opstartsgebyr?',
    answer:
      'Vi forsøger for så vidt muligt at gøre Sails så brugervenligt og selvforklarende som muligt. Det betyder, at du ikke skal igennem et langt kursus for at komme igang. Derfor har vi heller ikke nogen opsætnings- eller opstartsgebyrer.',
  },
  {
    question: 'Hvad hvis jeg overskrider grænserne for mit abonnement?',
    answer:
      'Vi opdaterer automatisk dit abonnement når en en af dine invitationer accepteres, eller du fjerner en bruger fra din konto. Skulle det ske, at ændringen i dit abonnement ikke sker automatisk, tager vi kontakt til dig og sikrer os at du får dit abonnement til den rigtige pris.',
  },
  {
    question: 'Er min data sikker?',
    answer:
      'Vi går meget op i at holde din data, dine kunders data og dine leverandørers data sikker. Alle vores servere er ISO-certificeret og placeret fysisk i EU. Al trafik er krypteret med SSL og vi har en streng politik til adgangskoder, som hjælper dig holde din adgang og data sikker. Derudover er Sails bygget med rolle-adgang, som hjælper dig definere hvilke medarbejdere der skal kunne se, ændre og slette dine data. Du kan læse mere i vores <a href="/privacy">privatlivspolitik</a> og i vores <a href="/dpa">databehandleraftale</a>.',
  },
  {
    question: 'Er Sails GDPR compliant?',
    answer:
      'Sails er en dansk-udviklet software-løsning, med servere placeret i EU. Vi tager vores ansvar som dataansvarlig og databehandler meget alvorligt og vi overholder de til enhver tid gældende love, herunder dataforordningen (GDPR). Du kan læse meget mere om vores rolle som dataansvarlig og databehandler og hvordan vi behandler dine data i vores <a href="/privacy">privatlivspolitik</a> og i vores <a href="/dpa">databehandleraftale</a>.',
  },
];

const PricingPage = () => {
  const sliderRef = useRef<ReactSlider<number>>(null);
  const [seats, setSeats] = useState(4);

  return (
    <Layout headerClass="md:bg-green-50">
      <SEO title="Priser" />
      <div className="bg-green-50 w-full pt-36 pb-48">
        <div className="container">
          <h1 className="heading-sm text-center text-green-900">
            Simpel og fleksibel pris
          </h1>
          <h2 className="sub font-bold text-center mt-3 text-green-700">
            Alt du har brug for til salgs- og indkøbsprocessen i din virksomhed
          </h2>
          <div className="block mx-auto text-center mt-8">
            <CTAButton />
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto -mt-36 px-2">
        <div className="rounded-3xl bg-white shadow-xl w-full px-6 py-8 md:px-8">
          <div className="grid grid-cols-2">
            <div>
              <p className="font-bold text-xs md:text-sm uppercase">
                Medarbejdere
              </p>
              <p className="font-mono font-bold text-xl md:text-3xl">
                {seats < 25 ? seats : '25+'}
              </p>
            </div>

            <div className="text-right">
              <p className="font-bold text-xs md:text-sm uppercase">Din pris</p>
              <p className="font-mono font-bold text-xl md:text-3xl">
                {seats < 25
                  ? `${(seats * 250).toLocaleString()}kr/mnd`
                  : 'Variabel'}
              </p>
              <p className="text-xs md:text-sm">
                {seats < 25
                  ? 'Svarer til 250kr per medarbejder'
                  : 'Tag en snak med os hvis I er flere'}
              </p>
            </div>
          </div>

          <div className="my-2 w-full">
            <Slider
              min={1}
              max={25}
              ref={sliderRef}
              value={seats}
              onChange={(v) => setSeats(v)}
            />
          </div>

          <div className="flex items-center mt-8">
            <span className="font-bold text-xs md:text-sm uppercase text-indigo-600 mr-6 flex-shrink-0">
              Alt er inkluderet
            </span>
            <span className="h-0.5 w-full bg-gray-100 rounded-full" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 mt-4">
            {features.map((feature, i) => (
              <div key={i} className="flex my-2">
                <CheckCircleIcon className="h-5 w-5 text-green-500 mr-4 mt-0.5" />
                <p>{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="max-w-3xl mx-auto my-20 md:my-36">
          <h2 className="heading-xs md:text-center">
            Gør som Artwork Systems Nordic A/S
          </h2>
          <Testimonial testimonial={testimonials[0]} className="my-10" />
        </div>

        <div className="max-w-3xl mx-auto my-20 md:my-36">
          <h2 className="heading-xs md:text-center mb-10">
            Ofte stillede spørgsmål
          </h2>
          {faqs.map((faq, i) => (
            <Accordion
              key={i}
              heading={faq.question}
              sub={faq.answer}
              initiallyOpen={true}
            />
          ))}
        </div>
      </div>

      <CTA />
    </Layout>
  );
};

export default PricingPage;
